import React from 'react'
import { Link, graphql } from 'gatsby'

import { kebabCase } from 'lodash'

import Layout from '../components/Layout'
import AdSenseMultiplex from '../components/AdSense/AdSenseMultiplex'
const TagsPage = ({ data }) => {
  const allTags = data.allMarkdownRemark.group

  return (
    <Layout>
      <div className="blog-tags">
        <h1>Tags</h1>
        <ul className="tags">
          {allTags.map((tag) => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="tag">
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
        <div className="tags-bottom-ads">
          <AdSenseMultiplex />
        </div>
      </div>
    </Layout>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
